
.live-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 25px);
    padding: 0 0 25px;
    position: relative;

    .live-wrap {
        display: flex;
        flex-direction: column;

        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                display: flex;
                flex-direction: column;
                padding: 0 20px;
            }
        }
    }

    .top-tab {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #F1F5FF;
        height: 40px;
        padding-bottom: 10px;

        .genera-breadcrumb {
            display: flex;
            align-items: center;

            &:before {
                content: unset;
            }
        }
    }

    .main-content {
        display: flex;
        flex-direction: column;
    }

    .content-item {
        margin-top: 20px;

        &:nth-of-type(2) {
            margin-top: 40px;
        }

        .item-content {
            margin-top: 20px;

            .top-item-content {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background: #F1F5FF;
                    bottom: -22px;

                }

                .top-left {
                    width: 356px;
                    height: 200px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .top-right {
                    height: 185px;
                    margin-left: 30px;

                    .item-title {
                        font-size: 24px;
                        color: #333333;
                    }

                    .right-content {
                        margin-top: 46px;
                        font-size: 18px;

                        .right-content-item + .right-content-item {
                            margin-top: 20px;
                        }

                        .content-right {
                            margin-left: 20px;
                        }
                    }
                }
            }

            .second-item-content {
                display: flex;

                .second-item {
                    width: 25%;
                    height: 120px;
                    margin-right: 20px;

                    &:last-child {
                        margin-right: 0;
                    }

                    .second-main-content {
                        padding: 23px 0 0 42px;
                        display: flex;
                        flex-direction: column;
                        color: #3F414E;

                        .second-top-title {
                            font-size: 16px;
                        }

                        .second-bottom-title {
                            margin-top: 20px;
                            font-size: 24px;
                        }
                    }
                }
            }

            .third-item-content {
                min-height: 240px;
                display: flex;
                flex-direction: column;

                .goods-infoBox {
                    display: flex;

                    .info-left {
                        width: 80px;
                        height: 80px;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    .info-right {
                        font-size: 16px;
                        color: #333333;
                        margin-left: 15px;
                        display: flex;
                        align-items: center;
                    }

                }
            }

            .fourth-item-content {
                min-height: 200px;
                display: flex;
                flex-direction: column;
            }

            .pages-center {
                margin-top: 0;
            }
        }
    }

    .content-title {
        position: relative;
        font-size: 20px;
        color: #333333;
        margin-left: 16px;

        &:before {
            position: absolute;
            content: '';
            width: 5px;
            height: 100%;
            background: #2DC079;
            left: -16px;
            border-radius: 3px;
            top: 0;
        }
    }
}
